import { Box, Container, Flex, globalCss, Icon, Text } from '@hope-ui/solid';
import {
  Component,
  createSignal,
  onCleanup,
  onMount,
  Show,
  useContext,
} from 'solid-js';
import { useLocation } from 'solid-app-router';

import { AiFillHeart } from 'solid-icons/ai';
import { BiSolidUserCircle } from 'solid-icons/bi';

import AppIcon from '../assets/appIcon.svg';
import { Button, Link } from '../components';
import { AuthContext } from '../userstore';

const IndexLayout: Component = (props) => {
  const [session] = useContext(AuthContext);
  const location = useLocation();

  let headerRef: HTMLDivElement;
  const [headerHeight, setHeaderHeight] = createSignal(0);

  const applyGlobalCss = globalCss({
    body: {
      background: '$background',
      backgroundAttachment: 'fixed',
    },
  });

  applyGlobalCss();

  const [headerPhased, setHeaderPhased] = createSignal(false);

  const onScroll = () => {
    if (window.scrollY >= 10 && !headerPhased()) {
      setHeaderPhased(true);
    } else if (window.scrollY < 10 && headerPhased()) {
      setHeaderPhased(false);
    }
  };

  onMount(() => {
    document.addEventListener('scroll', onScroll);

    setHeaderHeight(headerRef.clientHeight);
  });

  onCleanup(() => {
    document.removeEventListener('scroll', onScroll);
  });

  return (
    <Flex flexDirection='column' minH='100vh'>
      <Flex
        ref={headerRef}
        position='fixed'
        p='$4'
        w='$full'
        justifyContent='space-between'
        alignItems='center'
        bg={headerPhased() ? '$blackAlpha10' : undefined}
        transition='all 0.2s ease-in-out'
        css={{
          backdropFilter: headerPhased() ? 'blur(4px)' : '',
        }}
        zIndex={1}
      >
        <Flex flexDirection={'row'} gap='$4' alignItems='center'>
          <Link href='/'>
            <Icon as={AppIcon} boxSize='2rem' />
          </Link>
          <Text
            display={{ '@initial': 'none', '@md': 'inline-block' }}
            fontSize='$2xl'
            fontWeight='$light'
            lineHeight='1.9rem'
            userSelect={headerPhased() ? 'initial' : 'none'}
            opacity={headerPhased() ? 1 : 0}
            transform={headerPhased() ? 'translateX(0)' : 'translateX(20px)'}
            transition='all 0.2s ease-in-out'
          >
            Drop Research Facilities
          </Text>
        </Flex>
        <Box d='flex' gap='$4' alignItems='center'>
          <Show
            when={session.user}
            fallback={
              <Link href='https://api.drf.rs/auth/login'>
                <Button>Login</Button>
              </Link>
            }
          >
            <Text d='flex' gap='$2' alignItems='center' fontSize='$xl'>
              <Icon as={BiSolidUserCircle} boxSize='$6' /> {session.user.name}
            </Text>
            <Link href='/dashboard'>
              <Button>Dashboard</Button>
            </Link>
          </Show>
        </Box>
      </Flex>

      <Box mt={location.pathname === '/' ? undefined : `${headerHeight()}px`}>
        {props.children}
      </Box>

      <Box flex={1} />

      <Box mt='$2' p='$6' bg='#030303'>
        <Container
          display='flex'
          flexDirection={{ '@initial': 'column', '@lg': 'row' }}
          gap={{ '@initial': '$6', '@lg': '$20' }}
        >
          <Flex flexDirection='column' gap='$2' flexGrow={1}>
            <Text fontWeight='$bold' color='$accentBlue'>
              <Icon as={AppIcon} color='white' mr='$2' />
              Drop Research Facilities
            </Text>
            <Text>
              The ultimate loot tracking tool for Guild Wars 2.<br></br>
              No more waiting for API updates 🎉
            </Text>
            <Text fontSize='$xs' maxW='38rem'>
              Drop Research Facilities is a third-party site built to provide
              information and services to assist players who play Guild Wars 2.
              Drop Research Facilities is in no way associated with ArenaNet,
              NCSOFT Corporation or the Guild Wars 2 trademark.
            </Text>
          </Flex>
          <Flex
            flexDirection='column'
            gap='$2'
            textAlign={{ '@initial': 'left', '@lg': 'right' }}
          >
            <Text fontWeight='$bold' color='$accentBlue'>
              Legal
            </Text>
            <Link href='/legal-notice' textDecoration='none'>
              Legal Notice
            </Link>
            <Link href='/privacy-policy' textDecoration='none'>
              Privacy Policy
            </Link>
          </Flex>
          <Flex
            flexDirection='column'
            gap='$2'
            textAlign={{ '@initial': 'left', '@lg': 'right' }}
          >
            <Text fontWeight='$bold' color='$accentBlue'>
              Useful Links
            </Text>
            <Link href='/getting-started' textDecoration='none'>
              Getting Started Guide
            </Link>
            <Link href='/dashboard' textDecoration='none'>
              Dashboard
            </Link>
            <Link
              href='https://discord.gg/VSgehyHkrD'
              target='_blank'
              textDecoration='none'
            >
              Discord
            </Link>
          </Flex>
        </Container>
      </Box>
      <Box p='$6' bg='black'>
        <Container
          display='flex'
          flexDirection={{ '@initial': 'column', '@md': 'row' }}
          justifyContent={{ '@initial': 'center', '@md': 'space-between' }}
          textAlign={{ '@initial': 'center', '@md': 'left' }}
          gap='$2'
        >
          <Text fontSize='$sm'>
            Made with <Icon as={AiFillHeart} color='$accentBlue' /> from{' '}
            <Text as='span' color='$accentBlue'>
              Germany
            </Text>
            , and the{' '}
            <Text as='span' color='$accentBlue'>
              United Kingdom
            </Text>
            .
          </Text>
          <Text fontSize='$sm'>© {new Date().getFullYear()} Drop Research Facilities</Text>
        </Container>
      </Box>
    </Flex>
  );
};

export default IndexLayout;
